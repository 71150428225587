/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import url("https://fonts.googleapis.com/css?family=Indie+Flower&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css");
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,200;0,300;0,400;0,500;1,400&display=swap');

.scroll-preto{
    &::-webkit-scrollbar {
        background-color:transparent;
        width:16px 
   }
   /* background of the scrollbar except button or resizer */
    &::-webkit-scrollbar-track {
        background-color:transparent; 
   }
    &::-webkit-scrollbar-track:hover {
        background-color:transparent;
   }
   /* scrollbar itself */
    &::-webkit-scrollbar-thumb {
        background-color:#a0a0a5;
        border-radius:16px;
        border:4px solid black; 
   }
    &::-webkit-scrollbar-thumb:hover {
        background-color:#a0a0a5;
        border-radius:16px;
        border:4px solid black; 
   }
   /* set button(top and bottom of the scrollbar) */
    &::-webkit-scrollbar-button {
        display:none 
   }
}

.scroll-branco{
    &::-webkit-scrollbar {
        background-color:transparent;
        width:16px 
   }
   /* background of the scrollbar except button or resizer */
    &::-webkit-scrollbar-track {
        background-color:transparent; 
   }
    &::-webkit-scrollbar-track:hover {
        background-color:transparent;
   }
   /* scrollbar itself */
    &::-webkit-scrollbar-thumb {
        background-color:#a0a0a5;
        border-radius:16px;
        border:4px solid white; 
   }
    &::-webkit-scrollbar-thumb:hover {
        background-color:#a0a0a5;
        border-radius:16px;
        border:4px solid white; 
   }
   /* set button(top and bottom of the scrollbar) */
    &::-webkit-scrollbar-button {
        display:none 
   }
}

.scroll-vermelho{
    &::-webkit-scrollbar {
        background-color:transparent;
        width:16px 
   }
   /* background of the scrollbar except button or resizer */
    &::-webkit-scrollbar-track {
        background-color:transparent; 
   }
    &::-webkit-scrollbar-track:hover {
        background-color:transparent;
   }
   /* scrollbar itself */
    &::-webkit-scrollbar-thumb {
        background-color:#fff;
        border-radius:16px;
        border:4px solid #A84448; 
   }
    &::-webkit-scrollbar-thumb:hover {
        background-color:#fff;
        border-radius:16px;
        border:4px solid #A84448; 
   }
   /* set button(top and bottom of the scrollbar) */
    &::-webkit-scrollbar-button {
        display:none 
   }
}



.modalAtributos {
    --backdrop-opacity: 1;
    backdrop-filter: blur(3px);

    &::part(backdrop) {
        --background: #000;
    }

    &::part(handle) {
        background: #000;
        width: 150px;
        height: 6px;
    }

    &::part(content) {
        border-radius: 0px;
        box-shadow: 0px 0px 20px 5px rgb(0 0 0 / 32%);
    }
}

.transparent-modal {
    --background: #0000005c;
    &::part(content) {
        backdrop-filter: blur(12px);
    }
    ion-content {
        --background: transparent;
    }
}
